import api from '@api'
import {
  transformQualificationForServer,
  // buildNameFilterQuery,
  buildFlatQualificationStructure,
} from '@data/qualification'

import {
  getRootTranslator,
  getRootPluaralTranslator,
} from '@utils/get-module-translator'

import {
  transformUserGroupForList,
  transformGroupForCrud,
  transformGroupForServer,
} from '@data/user-group'
import { transformUserForList } from '@data/user'
import { transformSupportData } from '@data/workflow'

const __rootTc = getRootPluaralTranslator()
const __rootT = getRootTranslator()

export function getSupportDataApi(type) {
  return api
    .get(`/${type}/group/supporteddata`)
    .then((data) => transformSupportData(data))
}

export function getGroupsApi(type, { quals }, limit, offset, params = {}) {
  return api
    .post(
      `${type}/group/search/byqual`,
      {
        ...(quals
          ? {
              qualDetails: buildFlatQualificationStructure(
                [...(quals || [])].filter(Boolean)
              ),
            }
          : {}),
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
          ...params,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserGroupForList),
        total: data.totalCount,
      }
    })
}

export function createGroupApi(type, data) {
  return api.post(`${type}/group`, transformGroupForServer(data), {
    message: __rootT('created_successfully', {
      resource: `${__rootTc(type)} ${__rootTc('group')}`,
    }),
  })
}

export function getGroupApi(type, id) {
  return api.get(`${type}/group/${id}`).then(transformGroupForCrud)
}

export function updateGroupApi(type, data) {
  return api.patch(`${type}/group/${data.id}`, transformGroupForServer(data), {
    message: __rootT('updated_successfully', {
      resource: `${__rootTc(type)} ${__rootTc('group')}`,
    }),
  })
}

export function deleteGroupApi(type, id) {
  return api.delete(`${type}/group/${id}`, {
    message: __rootT('deleted_successfully', {
      resource: `${__rootTc(type)} ${__rootTc('group')}`,
    }),
  })
}

export function groupsPreviewApi(type, data, limit, offset) {
  return api
    .post(
      `${type}/group/preview`,
      {
        ...data,
        qual: data.qual ? transformQualificationForServer(data.qual) : {},
      },
      {
        params: {
          offset: offset || 0,
          size: limit === undefined ? 1000 : limit,
        },
        notify: false,
      }
    )
    .then((data) => {
      return {
        items: (data.objectList || []).map(transformUserForList),
        total: data.totalCount,
      }
    })
}
