<template>
  <FlotoCrudContainer
    ref="crudContainerRef"
    :fetch-fn="fetchFn"
    :columns="tableColumns"
    :show-no-data="false"
    :scrollable="false"
    as-table
  >
    <template v-slot:add-controls>
      <MRow>
        <MCol :siz="12" class="mb-2">
          <h5 class="text-ellipsis font-normal">
            {{ fieldName }} {{ $tc('transition') }}
          </h5>
          <TransitionBar :stack-data="transitionBarData" />
        </MCol>
      </MRow>
    </template>
    <template v-slot:form>
      <span />
    </template>
    <template v-slot:createdAt="{ item }">
      <td class="text-ellipsis">
        {{ item.createdAt | datetime(undefined, true) }}
      </td>
    </template>
    <template v-slot:transition="{ item }">
      <td class="text-ellipsis">
        {{ item.oldTransitionFieldName }} ->
        {{ item.newTransitionFieldName }}
      </td>
    </template>
    <template v-slot:duration="{ item }">
      <td class="text-ellipsis">
        {{ item.duration | duration }}
      </td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import { getTransitionDataApi } from '../../../ticket-api'
import TransitionBar from './transition-bar'

export default {
  name: 'TransitionDetail',
  components: { TransitionBar },
  props: {
    filters: {
      type: Object,
      default() {
        return {}
      },
    },
    // eslint-disable-next-line
    disabled: { type: Boolean, default: true },
    fieldName: { type: String, default: undefined },
    moduleName: { type: String, required: true },
    transitionType: { type: String, required: true },
    resourceId: { type: Number, required: true },
  },
  data() {
    return {
      transitionBarData: [],
    }
  },
  computed: {
    tableColumns() {
      return [
        { name: `${this.$tc('user')}`, key: 'performerName' },
        { name: `${this.$tc('date')}`, key: 'createdAt' },
        {
          name: `${this.fieldName} ${this.$tc('transition')}`,
          key: 'transition',
        },
        { name: `${this.$tc('duration')}`, key: 'duration' },
      ]
    },
  },
  methods: {
    fetchFn() {
      return getTransitionDataApi(
        this.moduleName,
        this.resourceId,
        this.transitionType
      ).then((data) => {
        this.transitionBarData = data.data
        return data
      })
    },
  },
}
</script>
