export function getFieldByRequesterAccess(fields, user) {
  const allFields = fields.filter((field) => {
    if (user && user.id) {
      const access = [...(field.requesterGroups || [])].length === 0
      const requesterGroupsAccess =
        [...(user.groups || [])].reduce((result, g) => {
          const requesterGroups = field.requesterGroups || []
          if (requesterGroups.indexOf(g) >= 0) {
            result.push(g)
          }
          return result
        }, []).length > 0
      return access || requesterGroupsAccess
    } else {
      const access = [...(field.requesterGroups || [])].length === 0
      return access
    }
  })
  return allFields
}
