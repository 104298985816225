<template>
  <!-- <a @click="$emit('done')"> -->
  <a :href="printUrl" target="_blank">
    <MIcon name="print" class="action-menu-icon" />
    <span class="ml-1 action-menu-text">
      {{ $t('print') }}
    </span>
  </a>
</template>

<script>
import { authComputed } from '../../../../state/modules/auth'
export default {
  name: 'Print',
  props: {
    moduleName: { type: String, required: true },
    resourceId: { type: Number, required: true },
  },
  computed: {
    ...authComputed,
    printUrl() {
      if (this.accessToken) {
        return `/api/analytics/download/print/${this.moduleName}/${[
          this.resourceId,
        ]}
          ?mid=${this.accessToken}`
      }
      return undefined
    },
  },
}
</script>
